import dynamic from 'next/dynamic';
import { fetchPancake } from '@/hooks/fetchPancake';
import axios from 'axios';
import { fetchUniswap } from '@/hooks/Uniswap';
import { Basket, BinanceTokens, XTokens } from '@/data/data.js';

const Homepage = dynamic(() => import("../components/Main/main"));

export default function Home({ tweets, result }) {

  return (
    <Homepage result={result} tweets={tweets} />
  )
}

export async function getStaticProps(context) {
  
  const bskt = await fetchPancake();

  const fetchTweets = async () => {
    try {
      const raw = await fetch(`https://api.twitter.com/2/users/1343876295436939264/tweets?max_results=3`,
        {
          headers: {
            Authorization: 'Bearer AAAAAAAAAAAAAAAAAAAAAFxQxAEAAAAADqavUDfGw2iLUusRzy%2F3kKIalVo%3DZCWOtQwC4vlSDgwjJB3hMYL1j4njtA3cTtUwR5elMWDlLr53gH'
          }
        }
      );
      const res = await raw.json();
      if(res?.data) {
        const tweetIds = res?.data?.map((tweet) => ({id: tweet.id}))
        const data = {data: tweetIds, status: res.status}
        return data;
      } else {
        const data = {data: [{id: '1874149758576595228'}, {id: '1864255210807234613'}, {id: '1861426385098604907'}], status: 429}
        return data 
      }
    } catch (e) {
      console.log('Fetching the tweet failed with: ', e)
      const data = {data: [{id: '1874149758576595228'}, {id: '1864255210807234613'}, {id: '1861426385098604907'}], status: 429}
      return data
    }
  }

  const tweets = await fetchTweets();

  try {

    const getCoingeckoParams = async () => {
      const basketTickers = Basket.map(item => item.ticker).filter(Boolean);
      const xtokenTickers = XTokens.map(item => item.ticker).filter(Boolean);
    
      const allTickers = [...new Set([...basketTickers, ...xtokenTickers])];
    
      return allTickers.join(',');
    };
    
    const coingeckoParams = await getCoingeckoParams();
    
    const res = await fetch(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coingeckoParams}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
    );
    
    const filteredCoins = await res.json();
    
    // Update Basket prices
    Basket.forEach(x => {
      const obj = filteredCoins?.find(o => o.id === x.ticker);
      if (obj && !x.price) {
        x.price = obj.current_price;
      }
      if (!x.value && x.price) {
        x.value = Math.round(x.price * x.amount);
      }
    });
    
    // Update XTokens prices
    XTokens.forEach(x => {
      const obj = filteredCoins?.find(o => o.id === x.ticker);
      if (obj && !x.price) {
        x.price = obj.current_price;
      }
      if (!x.value && x.price) {
        x.value = Math.round(x.price * x.amount);
      }
    });
    
    // Calculate total values
    const basketTotal = Basket.reduce((total, token) => total + (token.value || 0), 0);
    const xTokensTotal = XTokens.reduce((total, token) => total + (token.value || 0), 0);
    
    const result = basketTotal + xTokensTotal;

    return {
      props: { bskt, result, tweets },
      revalidate: 30,
    };
  } catch (e) {
    const result = 1200000
    return {
      props: { bskt, result, tweets },
      revalidate: 30,
    };
  }
}

// const { rawdata } = await axios.get(
//   `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@basketcoin`
// );

// const extractFirstUrl = (content) => {
//   const regex = /src="([^"]+)"/;
//   const match = regex.exec(content);
//   return match ? match[1] : null;
// };

// const data = await rawdata?.items?.slice(0, 3);
